import React, {useState} from "react"
// import LinkTransition from '../common/link-transition';

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import SubFeatureDetail from '../components/subfeature-detail';

import Ready from '../components/ready';

import illuReady from '../images/illu-ready.svg';
import ChannelVisual from '../images/channel-visual.svg';
import LiveColabVisual from '../images/live-colab-visual.svg';

const ContentSample = () => {
  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null
  });

  return (
    <Layout
      socialDark={true}
      currentPage='content-sample'
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Content Sample" />

      <Section>
        <ContentHero
          headlineLabel="Product tour"
          headlineUrl="#"
          title="Content creation"
          text="<p>When you add a text block in Ludus, you have access to a variety of fonts. You can either access the +800 Google fonts library, use exclusive fonts designed for Ludus or upload your own custom fonts.</p>"
        />
      </Section>

      <Section>
        <SubFeatureDetail
          title="Channels"
          text="Organise, set, credentials or even share a set of presentation with some external collaborators, the new dashboard allows you to finally have all your presentations organised the way it should be"
          details={[
            {
              title: 'Invite all members of your team',
              text: 'Organise, set, credentials or even share a set of presentation with some external collaborators, the new dashboard allows you to finally have all your presentations organised the way it should be'
            },
            {
              title: 'Set some channels private',
              text: 'Organise, set, credentials or even share a set of presentation with some external collaborators, the '
            },
            {
              title: 'Share with external teams',
              text: 'Organise, set, credentials or even share a set of presentation with some external collaborators, the new dashboard allows you to finally have all your presentations organised the way it should be'
            }
          ]}
          visual={{
            image: ChannelVisual,
            alt: 'Channels'
          }}
        />
      </Section>

      <Section
        light={true}
      >
        <SubFeatureDetail
          reverse={true}
          title="Live collaboration"
          text="Organise, set, credentials or even share a set of presentation with some external collaborators, the new dashboard allows you to finally have all your presentations organised the way it should be"
          details={[
            {
              title: 'Invite all members of your team',
              text: 'Organise, set, credentials or even share a set of presentation with some external collaborators, the new dashboard allows you to finally have all your presentations organised the way it should be'
            },
            {
              title: 'Set some channels private',
              text: 'Organise, set, credentials or even share a set of presentation with some external collaborators, the '
            },
            {
              title: 'Share with external teams',
              text: 'Organise, set, credentials or even share a set of presentation with some external collaborators, the new dashboard allows you to finally have all your presentations organised the way it should be'
            }
          ]}
          link={{
            label: '... and much more',
            url: '#'
          }}
          visual={{
            image: LiveColabVisual,
            alt: 'Live collaboration'
          }}
        />
      </Section>

      <Section>
        <br /><br /><br /><br /><br /><br /><br /><br /><br />
      </Section>

      <Section
        light={true}
      >
        <div style={{
          display: (currentBreakPoint.bp === 'm' || currentBreakPoint.bp === 's' || currentBreakPoint.bp === 'xs') ? 'none' : 'block',
          position: 'absolute',
          zIndex: '4',
          width: '45%',
          paddingBottom: '45%',
          top: '-17%',
          right: '0',
          background: `transparent url(${illuReady}) no-repeat 100% 50%`,
          backgroundSize: 'contain',
        }} />
        <Ready />
      </Section>

      
    </Layout>
  );
};

export default ContentSample;
